import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router";
const CartOrderButton = (props) => {
  console.log("props in CartOrderButton", props)
  let navigate = useNavigate();
  return (
    <>
      <Row className="pt-1" id="action-btn">
        <Col span={12}>
          <Button
            type="primary"
            onClick={() => {
              props.handleCancel();
              navigate("/checkout");
            }}
          >
            Fortsett
          </Button>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => {
              props.handleCancel();
            }}
          >
            Legg til mer
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CartOrderButton;
