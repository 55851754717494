import React from "react";
import ConfirmPorduct from "./SuperCategory/ProductCategory/Product/ConfirmPorduct";
const OrderNowButon = (props) => {
  console.log("komal is looking for props in ordernowbutton", props)
  return (
    <ConfirmPorduct
      link={"BESTIL NA"}
      isMobile={props.isMobile}
      onCloseMobileProductModal={props.onCloseMobileProductModal}
      handleCancelModal={props.handleCancelModal}
      ing={props.ing}
      singleProduct={props.singleProduct}
    />
  );
};
export default OrderNowButon;
