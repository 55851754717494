import React, { useState } from "react";
import { Modal } from "antd";

import ConfirmOrder from "../../components/Cart/ConfirmOrder";
const ConfirmOrderModal = (props) => {
  console.log("props i confirm modal", props)
  const [isModalVisible, setIsModalVisible] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    props.onCancelConfrimModal();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    props.onCancelConfrimModal();
    setIsModalVisible(false);
  };
  const handleCartModalClose = () => {
    props.onCancelConfrimModal();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title="Bekreft ordre"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ConfirmOrder handleCancel={handleCancel} />
    </Modal>
  );
};

export default ConfirmOrderModal;
